var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',[_c('div',{staticClass:"container"},[_c('h1',[_vm._v("Список пользователей")]),_c('ThisComponent',{attrs:{"list-params":[
        {
          name: 'active_tasks_count',
          label: 'Задания',
          filter: false,
          type: 'number'
        }
      ]}}),_c('br')],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }